import * as React from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { styled } from "@mui/material/styles"

function createData(name, purpose, propia, information) {
  return { name, purpose, propia, information }
}

const rows = [
  createData(
    "_ga",
    "Analiticas",
    "Externa (Google Analytics)",
    "Usada para diferenciar usuarios"
  ),
  createData(
    "_gat",
    "Analiticas",
    "Externa (Google Analytics)",
    "Limitar número de peticiones"
  ),
  createData(
    "_gid",
    "Analiticas",
    "Externa (Google Analytics)",
    "Usada para diferenciar usuarios"
  ),
  createData(
    "UserFrosting",
    "Auth/Session token",
    "Propio/a",
    "Identificar todos los requests pertenecientes a la misma sesión"
  ),
  createData(
    "1P_JAR",
    "Analytics",
    "Externa (Google Analytics) ",
    "Establecer una identificación única para acceder servicios g suite"
  ),
  createData("_cfduid", "Internas", "Externas (jQuery)", "Needed by jQuery"),
  createData(
    "_cfduid",
    "Seguridad",
    "Externa (Cloudflare)",
    "Identificar clientes individuales, para recordar configuraciones del usuario"
  ),
  createData(
    "_biz_flasgA",
    "Seguridad",
    "Externa (Cloudflare)",
    "Identificar clientes individuales, para recordar configuraciones del usuario"
  ),
  createData(
    "_biz_nA",
    "Seguridad",
    "Externa (Cloudflare)",
    "Identificar clientes individuales, para recordar configuraciones del usuario"
  ),
  createData(
    "_biz_flasgA",
    "Seguridad",
    "Externa (Cloudflare)",
    "Identificar clientes individuales, para recordar configuraciones del usuario"
  ),
  createData(
    "_biz_pendingA",
    "Seguridad",
    "Externa (Cloudflare)",
    "Identificar clientes individuales, para recordar configuraciones del usuario"
  ),
  createData(
    "_biz_uid",
    "Seguridad",
    "Externa (Cloudflare)",
    "Identificar clientes individuales, para recordar configuraciones del usuario"
  ),
  createData(
    "_mkto_trk",
    "Seguridad",
    "Externa (Cloudflare)",
    "Identificar clientes individuales, para recordar configuraciones del usuario"
  ),
]

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#36a7d4",
    color: theme.palette.common.white,
  },
}))

export default function TableCookies() {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell align="left">Purpose</StyledTableCell>
            <StyledTableCell align="left">Propia/Externa</StyledTableCell>
            <StyledTableCell align="left">
              Información Adicional
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow
              key={row.name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="left">{row.purpose}</StyledTableCell>
              <StyledTableCell align="left">{row.propia}</StyledTableCell>
              <StyledTableCell align="left">{row.information}</StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
