import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import { Box, Typography, Container } from "@material-ui/core"

import { makeStyles } from "@material-ui/core/styles"
import TableCookies from "../components/TableCookies"

const useStyles = makeStyles(theme => ({
  root: {},
  containerPart: {
    [theme.breakpoints.down("md")]: {
      marginTop: "6em",
    },
    marginTop: "10em",
  },
  linksStyles: {
    textDecoration: "underline",
    color: theme.palette.common.black,
    fontWeight: 500,
    "&:hover": {
      color: "#291C64",
    },
  },
}))

export default function Policy() {
  const classes = useStyles()
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>CFL | Aviso de Privacidad</title>
      </Helmet>
      <Container className={classes.containerPart}>
        <Box maxWidth="80%" m="auto">
          <Box mt={4} py={2}>
            <Typography variant="h1" component="h1" gutterBottom>
              <b>Aviso de Privacidad</b>
            </Typography>
          </Box>
          <Box mb={4} py={2}>
            <Typography variant="h3" gutterBottom inline>
              <b>1. INTRODUCCIÓN</b>
            </Typography>
            <Typography variant="body1" gutterBottom inline align="justify">
              Emprendimientos Maussan y Gonzalez, S.A.P.I. de C.V., Institución
              de Financiamiento Colectivo, con el nombre comercial Capital
              Funding Lab (en adelante, CFL, Capital Funding Lab o la Sociedad),
              está debidamente constituida de conformidad con las leyes de
              México y es una sociedad autorizada para organizarse y operar como
              Institución de Financiamiento Colectivo por la Comisión Nacional
              Bancaria y de Valores, por lo que se encuentra autorizada,
              regulada y supervisada por las Autoridades Financieras. Lo
              anterior, mediante oficio número P176/2021 publicado en el Diario
              Oficial de la Federación el seis de abril de dos mil veintitrés.
              Asimismo, el Registro Federal de Contribuyentes de Capital Funding
              Lab es EMG160524DG8 y tiene sus oficinas registradas en Calle
              Puebla, número 237, Colonia Roma Norte, Alcaldía Cuauhtémoc,
              Código Postal 06700, Ciudad de México.
              <br /> <br />
              Capital Funding Lab le informa que sus datos personales aportados
              para la celebración del contrato al que pertenece este anexo,
              serán tratados de manera confidencial bajo los niveles de cuidado
              más altos de confidencialidad, con la finalidad de proveer, operar
              y registrar los productos o servicios contratados, así como
              también para cumplir las obligaciones relacionadas
            </Typography>
            <br />
            <Typography variant="h3" gutterBottom inline>
              <b>2. OBJETIVO</b>
            </Typography>
            <Typography variant="body1" gutterBottom inline align="justify">
              Este Aviso de Privacidad tiene como objetivo hacer del
              conocimiento del Titular de los datos personales, la información
              que es tratada y sus finalidades, así como los procedimientos para
              ejercer sus derechos de acceso, rectificación, cancelación y
              oposición, revocación del consentimiento y la limitación de uso y
              divulgación de los mismos. Todo ello, a fin de garantizar el
              derecho de los Titulares a la autodeterminación informativa.
              <br /> <br />
              El presente Aviso de Privacidad se pone a disposición de los
              Titulares de los datos personales en el sitio web:
              https://www.capitalfundinglab.com/.
            </Typography>
            <br />
            <Typography variant="h3" gutterBottom inline>
              <b>3. RESPONSABLE DEL TRATAMIENTO DE LOS DATOS PERSONALES</b>
            </Typography>
            <Typography variant="body1" gutterBottom inline align="justify">
              El responsable del tratamiento de los datos personales de los
              Titulares es Capital Funding Lab, quien se compromete a respetar
              lo establecido en el presente Aviso de Privacidad, así como las
              disposiciones de la Ley Federal de Protección de Datos Personales
              en Posesión de los Particulares y su Reglamento.
            </Typography>
            <br />
            <Typography variant="h3" gutterBottom inline>
              <b>4. DATOS PERSONALES SOMETIDOS A TRATAMIENTO</b>
            </Typography>
            <Typography variant="body1" gutterBottom inline align="justify">
              En virtud del presente Aviso de Privacidad, Capital Funding Lab
              hace del conocimiento de los Titulares que someterá a tratamiento
              los datos personales que a continuación se señalan:
              <br />
              <br />
              Datos personales para Prospectos de Clientes
              <ul>
                <li>Datos de Identificación</li>
                <li>Datos de contacto</li>
                <li>Datos de Autentificación</li>
                <li>Datos Financieros y/o patrimoniales</li>
              </ul>
              Datos personales de Clientes
              <ul>
                <li>Datos de Identificación</li>
                <li>Datos de contacto</li>
                <li>Datos laborales</li>
                <li>Datos de autentificación</li>
                <li>Datos académicos </li>
                <li>Historial crediticio </li>
                <li>Datos financieros y/o patrimoniales </li>
                <li>Datos de terceros </li>
              </ul>
              Se hace del conocimiento de los Titulares que Capital Funding Lab
              no someterá a tratamiento datos personales sensibles.
              <br />
              <br />
              Al hacer uso de los servicios que presta Capital Funding Lab, éste
              tendrá acceso a la información personal que se le envíe y a la
              mantenida por los Proveedores de Servicio de Pago de Mantenimiento
              de Cuenta (por sus siglas en inglés, “ASPSP”) (es decir, cualquier
              proveedor de servicios de pago, como un banco o emisor de tarjetas
              de crédito, que mantenga una cuenta de pago a nombre del Titular)
              durante la extensión de la transmisión.
            </Typography>
            <br />
            <Typography variant="h3" gutterBottom inline>
              <b>5. FINALIDADES DEL TRATAMIENTO DE LOS DATOS PERSONALES</b>
            </Typography>
            <Typography variant="body1" gutterBottom inline align="justify">
              Los datos personales de los Titulares serán utilizados para las
              siguientes finalidades:
              <br />
              <br />
              1. Las que dan origen y son necesarias para la existencia,
              mantenimiento y cumplimiento de la relación jurídica entre el
              Titular y Capital Funding Lab tales como: proveer los servicios o
              productos solicitados; trámites, procesos y/o documentos para el
              otorgamiento, contratación y operación de los diversos productos y
              servicios que el Titular haya contratado; cumplir las obligaciones
              relacionadas con el producto o servicio contratado; consultas y
              reporteo en las Sociedades de Información Crediticia; cobranza
              extrajudicial y/o judicial; y la prevención y/o la detección de
              fraudes u otros ilícitos en agravio del Titular y/o de Capital
              Funding Lab.
              <br />
              <br />
              2. Para fines de: evaluar la calidad de los productos o servicios;
              y asesoría, estadística, publicidad, promoción y mercadotecnia,
              telemarketing o prospección comercial de los productos o servicios
              por cualquier medio material o electrónico.
              <br />
              <br />
              Aunado a lo anterior, al recabar sus datos personales se puede
              englobar en las siguientes categorías:
              <br />
              <br />
              Finalidades Primarias para Prospectos de Clientes:
              <ul>
                <li>Autentificar la identidad del Titular</li>
                <li>Verificar sus antecedentes crediticios</li>
                <li>
                  Evaluar y sopesar contable, administrativa y comercialmente el
                  inicio de una relación entre Capital Funding Lab y el Titular
                </li>
                <li>
                  Mantener la información necesaria en sistemas internos para
                  poder mantener contacto con el Titular
                </li>
                <li>
                  Enviar información de utilidad con la finalidad de que
                  invierta en los productos publicados en nuestra plataforma
                </li>
                <li>
                  Atender las solicitudes de ejercicio de derechos ARCO, de
                  revocación de consentimiento y brindar atención respecto a
                  cualquier duda, queja, aclaración o sugerencia
                </li>
              </ul>
              Finalidades Primarias para Clientes:
              <ul>
                <li>Autenticar la identidad del Titular</li>
                <li>
                  Darle de alta como Cliente y proporcionarle los servicios que
                  solicite o contrate
                </li>
                <li>
                  Contactarlo por cualquier medio que le hubiere conferido a
                  Capital Funding Lab para dar seguimiento a los servicios
                </li>
                <li>
                  Mantener la información necesaria actualizada en sistemas
                  internos para poder mantener contacto con el Titular, así como
                  para dar cumplimiento a disposiciones legales y posibles
                  requerimientos de autoridades competentes u órganos
                  regulatorios
                </li>
                <li>
                  Atender las solicitudes de ejercicio de derechos ARCO y de
                  revocación de consentimiento
                </li>
                <li>
                  Realizar evaluaciones periódicas de nuestros servicios a
                  efectos de mejorar la calidad de estos
                </li>
                <li>Gestionar la cobranza extrajudicial y judicial </li>
              </ul>
              Finalidades Secundarias para Prospectos de Clientes y Clientes:
              <ul>
                <li>
                  Enviar información publicitaria de Capital Funding Lab o de
                  terceros
                </li>
                <li>
                  Notificarle sobre nuevos servicios o productos que tengan
                  relación con los ya contratados o adquiridos
                </li>
                <li>Para fines estadísticos y de prospección comercial</li>
                <li>
                  Contestar comentarios, dudas, sugerencias, quejas y
                  recomendaciones
                </li>
              </ul>
            </Typography>
            <br />
            <Typography variant="h3" gutterBottom inline>
              <b>6. CONSENTIMIENTO</b>
            </Typography>
            <Typography variant="body1" gutterBottom inline align="justify">
              En términos de lo establecido en el artículo 8 de la Ley Federal
              de Protección de Datos Personales en Posesión de los Particulares,
              el Titular otorga su consentimiento expreso al momento de aceptar
              el presente Aviso de Privacidad para que Capital Funding Lab
              someta a tratamiento sus datos personales incluyendo aquellos de
              carácter financiero o patrimonial
              <br />
              <br />
              Capital Funding Lab solo utilizará sus datos personales para los
              fines para los que fueron recopilados, a menos que considere que
              se deben usar razonablemente por otros motivos, y siempre que la
              razón sea compatible con el propósito original. Si Capital Funding
              Lab necesita utilizar la información personal de los Titulares
              para un propósito no relacionado con aquél para el que se
              recopilaron los datos, se le notificará explicándole el motivo del
              nuevo requerimiento. En algunas circunstancias, se puede dejar de
              forma anónima los datos personales (para que ya no puedan
              asociarse con el Titular) con fines de investigación o
              estadísticos, en cuyo caso Capital Funding Lab podrá utilizar esta
              información indefinidamente sin previo aviso.
              <br />
              <br />
            </Typography>
            <Typography variant="h3" gutterBottom inline>
              <b>
                6.1. MEDIOS Y PROCEDIMIENTO PARA LA REVOCACIÓN DEL
                CONSENTIMIENTO
              </b>
            </Typography>
            <Typography variant="body1" gutterBottom inline align="justify">
              En cualquier momento, el Titular podrá revocar la autorización que
              se le ha otorgado a Capital Funding Lab para el tratamiento de sus
              datos personales. Sin embargo, Capital Funding Lab manifiesta que
              para el cumplimiento de ciertos fines la revocación del
              consentimiento podría provocar la terminación de la relación entre
              Capital Funding Lab y el Titular
              <br />
              <br />
              En caso de requerir la revocación, el Titular deberá observar el
              siguiente procedimiento:
              <ul>
                <li>
                  Envío de una solicitud a la siguiente dirección: Calle Puebla
                  número 237, Col. Roma Norte, C.P.06700, Alcaldía Cuauhtémoc,
                  Ciudad de México, en un horario de 9:00 a 13:00 horas o de
                  15:00 a 17:00 horas, o bien, vía correo electrónico a la
                  dirección complaints@capitalfundinglab.com, con el asunto
                  “Revocación de consentimiento”
                </li>
                <li>
                  La solicitud deberá contener los siguientes datos
                  <ul>
                    <li>Nombre completo del Titular</li>
                    <li>Motivo de la solicitud</li>
                    <li>
                      Los argumentos que sustenten la petición de la revocación
                      del consentimiento
                    </li>
                    <li>Fecha en la que desee sea cumplida la petición</li>
                  </ul>
                </li>
                <li>
                  La solicitud deberá acompañarse de los siguientes documentos
                  <ul>
                    <li>
                      Copia de la identificación oficial del Titular, previa
                      exhibición del original para su cotejo o, en su caso, la
                      representación legal del Titular mediante escritura
                      pública o carta poder firmada ante dos testigos junto con
                      identificación oficial tanto del Titular como de su
                      Representante Legal
                    </li>
                    <li>
                      Cualquier otro documento que facilite la localización de
                      los datos personales
                    </li>
                  </ul>
                </li>
              </ul>
              Capital Funding Lab, le hará llegar al Titular en la dirección
              proporcionada en la solicitud o vía correo electrónico desde el
              cual se hubiere remitido la solicitud, en un plazo máximo de
              veinte días, contados a partir de la fecha en que se recibió la
              solicitud de revocación de consentimiento, la determinación
              adoptada. Es necesario recalcar, que este plazo únicamente será
              aplicable cuando Capital Funding Lab cuente con la información
              suficiente para poder dar contestación a la petición.
              <br />
              <br />
              Asimismo, en caso de que la información proporcionada en la
              solicitud de revocación de consentimiento sea insuficiente o
              errónea para atenderla, o bien, no se acompañen los documentos que
              acrediten la identidad del Titular o de su Representante Legal,
              Capital Funding Lab, podrá requerir al Titular por una sola vez y
              dentro de los cinco días siguientes a la recepción de la
              solicitud, que aporte los elementos o los documentos necesarios
              para dar respuesta a la misma. Asimismo, el Titular contará con un
              plazo máximo de diez días para atender el requerimiento
              solicitado, en caso contrario se tendrá por no presentada la
              solicitud de revocación de consentimiento.
              <br />
              <br />
            </Typography>
            <Typography variant="h3" gutterBottom inline>
              <b>
                7. OPCIONES Y MEDIOS PARA LIMITAR EL USO O DIVULGACIÓN DE LOS
                DATOS PERSONALES
              </b>
            </Typography>
            <Typography variant="body1" gutterBottom inline align="justify">
              Los datos personales recabados, serán en todo caso tratados de
              manera lícita, leal y transparente, limitados exclusivamente a las
              finalidades establecidas, y acordes con el principio de exactitud
              y minimización. Asimismo, los datos personales recabados serán
              tratados de forma absolutamente confidencial y guardando el
              preceptivo deber de secreto respecto de los mismos, de acuerdo con
              lo previsto en la normativa de aplicación, adoptando al efecto las
              medidas de índole administrativas técnica y organizativas
              necesarias que garanticen la seguridad de sus datos y eviten su
              alteración, pérdida, tratamiento o acceso no autorizado, habida
              cuenta del estado de la tecnología, la naturaleza de los datos
              almacenados y los riesgos a que están expuestos.
              <br /> <br />
              En caso de que el Titular tenga alguna duda respecto del
              tratamiento que Capital Funding Lab le está dando a sus datos
              personales o bien quiere limitar el uso o divulgación de los
              mismos, podrá enviar una solicitud a la dirección: Calle Puebla
              237, Col. Roma Norte, C.P. 06700, Alcaldía Cuauhtémoc, Ciudad de
              México, o vía correo electrónico a complaints@capitalfundinglab,
              con el asunto “Limitación de Uso o Divulgación” expresando
              comentarios, dudas o sugerencias.
              <br /> <br />
              Aunado a lo anterior, el Titular podrá inscribirse en el Registro
              Público de Usuarios (en adelante, “REUS”), conforme a la Ley de
              Protección y Defensa al Usuario de Servicios Financieros con la
              finalidad de limitar el uso y divulgación de sus datos personales.
              Este registro permite que el Titular se inscriba en una base de
              datos a fin de restringir llamadas promocionales en números
              particulares (fijo o móvil), laborales o correo electrónico,
              buscando mantener a salvo su privacidad y evitar las molestias que
              causan estas llamadas o envío de información
              <br /> <br />
              El Titular podrá registrarse al REUS a través de los medios que se
              establecen en la siguiente página de Internet:{" "}
              <a
                href="https://webapps.condusef.gob.mx/reus/app/registro.jsp"
                target="_blank"
              >
                https://webapps.condusef.gob.mx/reus/app/registro.jsp
              </a>
              <br /> <br />
            </Typography>
            <br />
            <Typography variant="h3" gutterBottom inline>
              <b>8. DERECHOS ARCO</b>
            </Typography>
            <Typography variant="body1" gutterBottom inline align="justify">
              El Titular puede en cualquier momento, revocar los consentimientos
              prestados, así como solicitar a Capital Funding Lab, el ejercicio
              de los siguientes derechos:
              <br /> <br />
              Derecho de acceso, el Titular tiene derecho a obtener de Capital
              Funding Lab información sobre sus datos personales, así como
              información relativa a las condiciones y generalidades de su
              tratamiento y a obtener una copia de los mismos.
              <br /> <br />
              Derecho de rectificación, el Titular podrá solicitar a Capital
              Funding Lab, cambiar cualquiera de sus datos personales como por
              ejemplo la dirección en caso de que no esté completa o ésta haya
              cambiado, es decir, cuando se trate de información inexacta o
              incompleta (como podría ser un cambio en el domicilio o cualquier
              otra información proporcionada).
              <br /> <br />
              Derecho de cancelación, en determinados supuestos, tales como la
              retirada del consentimiento al tratamiento de datos, cuando el
              mismo sea ilícito o cuando los datos hayan dejado de ser
              necesarios.
              <br /> <br />
              Derecho a solicitar limitar el tratamiento de los datos personales
              de los Titulares en determinadas circunstancias, tales como cuando
              impugne la exactitud de los datos o cuando el tratamiento sea
              ilícito y el Titular se oponga a su supresión, en cuyo caso
              Capital Funding Lab los conservará únicamente para el ejercicio o
              la defensa de reclamaciones.
              <br /> <br />
              Derecho a la portabilidad de los datos, es decir, a recibir una
              copia electrónica de los datos personales de los Titulares o a
              solicitar que se transmitan directamente a otro responsable
              siempre que se cumplan los requisitos legalmente establecidos.
              <br /> <br />
              Derecho a oponerse al tratamiento de datos en determinadas
              circunstancias, en cuyo caso Capital Funding Lab dejará de tratar
              los datos, salvo por motivos legítimos imperiosos o el ejercicio o
              la defensa de posibles reclamaciones.
              <br /> <br />
            </Typography>
            <Typography variant="h3" gutterBottom inline>
              <b>8.1 PROCEDIMIENTO PARA EJERCER SUS DERECHOS ARCO</b>
            </Typography>
            <Typography variant="body1" gutterBottom inline align="justify">
              Si desea ejercer los derechos ARCO, deberá enviar una solicitud a
              la Unidad Especializada de Usuarios de Capital Funding Lab y
              entregarla en la siguiente dirección: Calle Puebla, número 237,
              Colonia Roma Norte, Alcaldía, Cuauhtémoc, Código Postal 06700
              Ciudad de México., entre las 09:00 y las 13:00 horas o entre las
              15:00 y las 17:00 horas de lunes a viernes, o bien por correo
              electrónico a la siguiente dirección:{" "}
              <a href="mailto:complaints@capitalfundinglab.com">
                complaints@capitalfundinglab.com
              </a>
              <br /> <br />
              La solicitud deberá contener los siguientes datos:
              <ul>
                <li>Nombre completo del Titular</li>
                <li>Domicilio</li>
                <li>
                  Descripción clara y precisa de los datos personales respecto
                  de los cuales pretenda ejercer alguno de los derechos ARCO
                </li>
                <li>
                  Especificación del derecho ARCO de desea ejercer, así como su
                  motivación
                </li>
                <li>
                  En el caso de que el Titular desee ejercer el derecho de
                  rectificación, deberá indicar a qué datos personales se
                  refiere, así como la corrección que se tenga que realizar y
                  deberá de ir acompañada de la documentación que ampare la
                  procedencia de lo solicitado
                </li>
                <li>Periódo de la información a la cual desea acceder</li>
              </ul>
              Asimismo, dicha solicitud deberá acompañarse de los siguientes
              documentos:
              <ul>
                <li>
                  Copia de la identificación oficial del Titular, previa
                  exhibición del original para su cotejo o, en su caso, la
                  representación legal del Titular mediante escritura pública o
                  carta poder firmada ante dos testigos junto con identificación
                  oficial tanto del Titular como de su Representante Legal
                </li>
                <li>
                  Cualquier otro documento que facilite la localización de los
                  datos personales
                </li>
              </ul>
              Capital Funding Lab, le entregará al Titular acuse de recibo y le
              hará llegar a la dirección proporcionada en la solicitud o vía
              correo electrónico desde el cual se hubiere remitido la solicitud,
              en un plazo máximo de veinte días, contados a partir de la fecha
              en que se recibió la solicitud de acceso, rectificación,
              cancelación u oposición, la determinación adoptada. Es necesario
              recalcar, que este plazo únicamente será aplicable cuando Capital
              Funding Lab cuente con la información suficiente para poder dar
              contestación a la petición.
              <br /> <br />
              En caso de que la información proporcionada en la solicitud sea
              insuficiente o errónea para atenderla, o bien, no se acompañen los
              documentos que acrediten la identidad del Titular o de su
              Representante Legal, Capital Funding Lab, podrá requerir al
              Titular por una sola vez y dentro de los cinco días siguientes a
              la recepción de la solicitud, que aporte los elementos o los
              documentos necesarios para dar respuesta a la misma. Asimismo, el
              Titular contará con un plazo máximo de diez días para atender el
              requerimiento solicitado, en caso contrario se tendrá por no
              presentada la solicitud correspondiente.
              <br /> <br />
              Si resulta procedente la solicitud, el acceso a los datos
              personales, los cambios solicitados, la cancelación o la respuesta
              a la oposición se realizarán en un plazo máximo de quince días
              contados a partir de la fecha en que se haya dado respuesta a la
              solicitud.
              <br /> <br />
              Tratándose de solicitudes de acceso a datos personales, procederá
              la entrega previa acreditación de la identidad del Titular o del
              Representante Legal. Dicha entrega se hará en copias simples en el
              domicilio mencionado en la solicitud, o bien, en documentos
              electrónicos que se enviará a la dirección de correo electrónico
              desde la que se hubiere remitido la solicitud
              <br /> <br />
              Los plazos antes referidos podrán ser ampliados una sola vez por
              un periodo igual
              <br /> <br />
              El acceso a la información se dará por cumplido cuando se pongan a
              disposición del Titular los datos personales vía correo
              electrónico o en el domicilio indicado en la solicitud.
              <br /> <br />
              Capital Funding Lab, podrá negar el acceso a los datos personales,
              o a realizar la rectificación o cancelación o conceder la
              oposición al tratamiento de los mismos, en los siguientes
              supuestos: (i) Cuando el solicitante no sea el Titular de los
              datos personales, o el representante legal no esté debidamente
              acreditado para ello; (ii) Cuando en su base de datos, no se
              encuentren los datos personales del solicitante; (iii) Cuando se
              lesionen los derechos de un tercero; (iv) Cuando exista un
              impedimento legal, o la resolución de una autoridad competente,
              que restrinja el acceso a los datos personales, o no permita la
              rectificación, cancelación u oposición de los mismos, y (v) Cuando
              la rectificación, cancelación u oposición haya sido previamente
              realizada.
              <br /> <br />
              La negativa planteada en el párrafo inmediato anterior, se le hará
              saber al Titular en la forma y términos planteados en el presente
              apartado.
              <br /> <br />
              En caso de no estar satisfecho con el servicio que Capital Funding
              Lab le proporciona, tiene derecho de solicitar el inicio del
              procedimiento de protección de derechos ante el Instituto Nacional
              de Transparencia, Acceso a la Información y Protección de Datos
              Personales. Para más información les sugerimos visitar su página
              oficial de Internet{" "}
              <a href="www.inai.org.mx" target="_blank">
                www.inai.org.mx
              </a>
              .
              <br /> <br />
            </Typography>
            <Typography variant="h3" gutterBottom inline>
              <b>
                9. RESPONSABLE DE TRAMITAR SOLICITUDES DE DERECHOS ARCO,
                LIMITACIÓN DE USO Y DIVULGACIÓN, ASÍ COMO REVOCACIONES DE
                CONSENTIMIENTO
              </b>
            </Typography>
            <Typography variant="body1" gutterBottom inline align="justify">
              En caso de que el Titular desee tramitar solicitud de acceso,
              rectificación, cancelación u oposición, limitar el uso o
              divulgación o que desee revocar el consentimiento del tratamiento
              de sus datos personales, deberá dirigirse al departamento de la
              Unidad Especializada de Usuarios, con dirección en Calle Puebla
              237, Col Roma Norte, C.P. 06700, Alcaldía Cuauhtémoc, Ciudad de
              México o vía correo electrónico a{" "}
              <a href="mailto:complaints@capitalfundinglab.com">
                complaints@capitalfundinglab.com
              </a>
            </Typography>
            <br />
            <Typography variant="h3" gutterBottom inline>
              <b>10. TRANSFERENCIAS DE DATOS PERSONALES</b>
            </Typography>
            <Typography variant="body1" gutterBottom inline align="justify">
              Con fundamento en lo dispuesto en los artículos 36 y 37, de la Ley
              Federal de Protección de Datos Personales en Posesión de los
              Particulares, los datos personales de los Titulares podrán ser
              transferidos lícitamente a terceros sin necesidad de su
              consentimiento únicamente en los siguientes casos: (i) cuando la
              transferencia esté prevista en una Ley o Tratado en los que México
              sea parte; (ii) cuando la transferencia sea efectuada a sociedades
              nacionales o internacionales controladoras, subsidiarias o
              afiliadas bajo el control común del responsable, o a una sociedad
              matriz o a cualquier sociedad del mismo grupo del responsable que
              opere bajo los mismos procesos y políticas internas; (iii) cuando
              la transferencia sea necesaria por virtud de un contrato celebrado
              o por celebrar en interés del Titular, por el responsable y un
              tercero, (iv) cuando la transferencia sea necesaria o legalmente
              exigida por alguna autoridad para salvaguardar un interés público,
              o para la procuración o administración de justicia, (v) cuando la
              transferencia sea precisa para el reconocimiento, ejercicio o
              defensa de un derecho en un proceso judicial y (vi) cuando la
              transferencia sea precisa para el mantenimiento o cumplimiento de
              una relación jurídica entre el responsable y el Titular.
              <br /> <br />
              Capital Funding Lab, podrá transferir los datos personales de los
              Titulares a personas que sean aliados comerciales, lo anterior con
              la finalidad de darle a conocer ofertas, nuevos productos o
              servicios que implemente Capital Funding Lab, o bien para lograr
              el objetivo del contrato que tenga celebrado con esta empresa.
              <br /> <br />
              Al momento de aceptar el presente Aviso de Privacidad, el Titular
              consiente de manera expresa la transferencia de sus datos
              personales a terceros y a aliados comerciales de Capital Funding
              Lab, quienes asumirán las mismas obligaciones que corresponden a
              Capital Funding Lab, respecto del tratamiento de sus datos.
              <br /> <br />
            </Typography>
            <Typography variant="h3" gutterBottom inline>
              <b>11. MODIFICACIONES AL AVISO DE PRIVACIDAD</b>
            </Typography>
            <Typography variant="body1" gutterBottom inline align="justify">
              Se hace del conocimiento al Titular, que este Aviso de Privacidad
              podrá ser modificado en cualquier momento por Capital Funding Lab,
              atendiendo siempre la legislación de la materia
              <br />
              <br />
              En caso de modificar este Aviso de Privacidad, se hará la
              notificación mediante la publicación en el sitio web con una fecha
              de vigencia actualizada y/o a través de otros medios como el envío
              de un correo electrónico a la dirección que el Titular hubiere
              proporcionado y/o la publicación de la noticia en la página web de
              Capital Funding Lab
              <br />
              <br />
              Cualquier cambio o actualización en el presente Aviso de
              Privacidad, podrá ser consultado en el Portal en la sección “Aviso
              de Privacidad”
            </Typography>
            <br />
            <Typography variant="h3" gutterBottom inline>
              <b>12. LINKS DE TERCEROS</b>
            </Typography>
            <Typography variant="body1" gutterBottom inline align="justify">
              La presente Política de Privacidad, así como los servicios que
              presta Capital Funding Lab, pueden contener links a páginas webs
              de terceros, a aplicaciones y a plugins. Accediendo a sus enlaces
              o permitiendo su conexión, el Titular puede facilitar que se
              recopilen o compartan sus datos. Asimismo, Capital Funding Lab le
              informa que no controla, ni es responsable de los sitios web de
              terceros a los que pueda acceder, ni a sus políticas de
              privacidad. Por lo consiguiente, se le recomienda al Titular que
              una vez abandone los servicios prestados por Capital Funding Lab,
              lea las políticas de privacidad de cada uno de estos sitios web
              que visite
            </Typography>
            <br />
            <Typography variant="h3" gutterBottom inline>
              <b>13. POLÍTICA DE COOKIES</b>
            </Typography>
            <Typography variant="body1" gutterBottom inline align="justify">
              A través del uso de cookies, Capital Funding Lab recopila
              información sobre los hábitos de navegación de sus usuarios,
              asimismo le permite distinguirlo de otros usuarios, para así poder
              mejorar el servicio ofrecido. Las cookies son archivos compuestos
              por letras y números que se pueden descargar en su equipo si las
              acepta. En el caso de Capital Funding Lab, el uso de cookies le
              permite mejorar la experiencia online de sus usuarios y obtener un
              mayor conocimiento del uso del sitio web, por ejemplo
              proporcionando información sobre si el usuario ha visitado el
              sitio web de Capital Funding Lab con anterioridad o si se trata de
              un nuevo usuario. El uso de las mismas también le ayuda a Capital
              Funding Lab a garantizar que los anuncios publicitarios que
              aparecen online sean acordes a sus intereses. Cuando el usuario
              navega por la página web, automáticamente Capital Funding Lab
              recibe la dirección de protocolo de internet del ordenador del
              usuario (por sus siglas en inglés, “IP”), lo cual le ayuda a
              Capital Funding Lab a proporcionarle una buena experiencia cuando
              navega por la página web, así como mejorar la misma. Los
              principales navegadores de internet le permiten a Capital Funding
              Lab colocar cookies en la navegación de sus usuarios, por lo que
              recomienda que mantenga esta posibilidad habilitada. De esta forma
              le permitirá a Capital Funding Lab utilizar todos sus recursos de
              navegación personalizada, pero en caso que el usuario no esté de
              acuerdo, puede deshabilitar esta función.
              <br />
              Una cookie es un pequeño archivo de letras y números que
              almacenamos en su navegador o en el disco duro de su computadora
              si está de acuerdo. Las cookies contienen información que se
              transfiere al disco duro de su computadora. Recopilamos datos
              sobre cómo interactúa con nuestro sitio web mediante el uso de
              cookies. Nuestro sitio web utiliza cookies para distinguirlo de
              otros usuarios de nuestro sitio web. Al continuar navegando por
              nuestro sitio web, acepta nuestro uso de cookies.
              <br />
              <br />
              Utilizamos las siguientes cookies:
              <ul>
                <li>
                  <b> Cookies estrictamente necesarias.</b> Estas son cookies
                  que se requieren para el funcionamiento de nuestro sitio web.
                  Incluyen, por ejemplo, cookies que le permiten iniciar sesión
                  en áreas seguras de nuestro sitio web, utilizar un carrito de
                  compras o utilizar los servicios de facturación electrónica.
                </li>
                <li>
                  <b> Cookies de funcionalidad.</b> Nos permiten reconocer y
                  contar el número de visitantes y ver cómo los visitantes se
                  mueven por nuestro sitio web cuando lo usan. Esto nos ayuda a
                  mejorar la forma en que funciona nuestro sitio web, por
                  ejemplo, al garantizar que los usuarios encuentren fácilmente
                  lo que buscan.
                </li>
                <li>
                  <b> Cookies estrictamente necesarias.</b> Estos se utilizan
                  para reconocerlo cuando regresa a nuestro sitio web. Esto nos
                  permite personalizar nuestro contenido para usted, saludarlo
                  por su nombre y recordar sus preferencias (por ejemplo, su
                  elección de idioma o región).
                </li>
                <li>
                  <b> Cookies de orientación.</b> Estas cookies registran su
                  visita a nuestro sitio web, las páginas que ha visitado y los
                  enlaces que ha seguido. Utilizaremos esta información para
                  hacer que nuestro sitio web y la publicidad que se muestra en
                  él sean más relevantes para sus intereses. También podemos
                  compartir esta información con terceros para este propósito.
                </li>
              </ul>
              Puede encontrar más información sobre las cookies individuales que
              utilizamos y los fines para los que las utilizamos en la siguiente
              tabla:
            </Typography>
            <br />
            <TableCookies />
            <br />
            <Typography variant="body1" gutterBottom inline align="justify">
              <b> Terceras partes:</b> Tenga en cuenta que terceros (incluidos,
              por ejemplo, redes de publicidad y proveedores de servicios
              externos como servicios de análisis de tráfico web) también pueden
              usar cookies, sobre las cuales no tenemos control. Es probable que
              estas cookies sean cookies analíticas / de rendimiento o cookies
              de orientación
              <br />
              <br />
              <b> ¿Cómo cambio mi configuración de cookies?</b> Tenga en cuenta
              que terceros (incluidos, por ejemplo, redes de publicidad y
              proveedores de servicios externos como servicios de análisis de
              tráfico web) también pueden usar cookies, sobre las cuales no
              tenemos control. Es probable que estas cookies sean cookies
              analíticas / de rendimiento o cookies de orientación
              <br />
              <br />
              Puede encontrar más información sobre cookies en{" "}
              <a href="www.aboutcookies.org" target="_blank">
                www.aboutcookies.org
              </a>{" "}
              or{" "}
              <a href="www.allaboutcookies.org" target="_blank">
                www.allaboutcookies.org
              </a>
              <br />
              <br />
              Para optar por no ser rastreado por Google Analytics en todos los
              sitios web, visite{" "}
              <a href="http://tools.google.com/dlpage/gaoptout" target="_blank">
                http://tools.google.com/dlpage/gaoptout
              </a>
            </Typography>
          </Box>
        </Box>
      </Container>
    </Layout>
  )
}
